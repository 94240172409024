import React from "react";
import GoogleMapReact from "google-map-react";
import tehnomatikIcon from "../../images/map/tehnomatikMarker.png";

const myMapStyles = [
	{
		featureType: "administrative",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#444444"
			}
		]
	},
	{
		featureType: "administrative.land_parcel",
		elementType: "all",
		stylers: [
			{
				visibility: "off"
			}
		]
	},
	{
		featureType: "landscape",
		elementType: "all",
		stylers: [
			{
				color: "#f5f5f5"
			}
		]
	},
	{
		featureType: "landscape.natural",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "poi",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			},
			{
				color: "#052366"
			},
			{
				saturation: "-70"
			},
			{
				lightness: "85"
			}
		]
	},
	{
		featureType: "poi",
		elementType: "labels",
		stylers: [
			{
				visibility: "simplified"
			},
			{
				lightness: "-53"
			},
			{
				weight: "1.00"
			},
			{
				gamma: "0.98"
			}
		]
	},
	{
		featureType: "poi",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "simplified"
			}
		]
	},
	{
		featureType: "road",
		elementType: "all",
		stylers: [
			{
				saturation: -100
			},
			{
				lightness: 45
			},
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "road",
		elementType: "geometry",
		stylers: [
			{
				saturation: "-18"
			}
		]
	},
	{
		featureType: "road",
		elementType: "labels",
		stylers: [
			{
				visibility: "off"
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "road.arterial",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "road.arterial",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "road.local",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "transit",
		elementType: "all",
		stylers: [
			{
				visibility: "off"
			}
		]
	},
	{
		featureType: "water",
		elementType: "all",
		stylers: [
			{
				color: "#57677a"
			},
			{
				visibility: "on"
			}
		]
	}
];

const TehnomatikMap = () => {
	
	const tehnomatikMarker = (map, maps) => {
		let marker = new maps.Marker({
			position: { lat: 43.6564001, lng: 20.8925622 },
			icon: tehnomatikIcon,
			map
		});
		return marker;
	};

	return (
		<div className="tehnomatikGoogleMap">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: "AIzaSyCYRedNuVd0Sh4PJZq2iBBAhj-_X4v2H6s",
					language: "en"
				}}
				options={{
					styles: myMapStyles,
					mapTypeControl: true,
					streetViewControl: false
				}}
				defaultCenter={{ lat: 43.6974001, lng: 20.9011111 }}
				defaultZoom={7}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => tehnomatikMarker(map, maps)}
			></GoogleMapReact>
		</div>

	);
};

export default TehnomatikMap;
