import React, { useEffect, useRef } from "react";
import SEO from "../components/seo/seo";
import { injectIntl } from "gatsby-plugin-react-intl";
import MyVideo from "../images/aboutPage/videoBackground.mp4";
import MyVideoMobile from "../images/aboutPage/videoBackgroundMobile.mp4";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import TehnomatikMap from "../components/googleMap/Google_map";
import FadeIn from "../components/animations/FadeIn";
import { gsap } from "gsap";

const About = ({ intl }) => {
	const images = useStaticQuery(graphql`{
  about1: file(relativePath: {eq: "aboutPage/about1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
    }
  }
  about2: file(relativePath: {eq: "aboutPage/about2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
    }
  }
  about3: file(relativePath: {eq: "aboutPage/about3.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
    }
  }
  aboutIcon: file(relativePath: {eq: "aboutPage/aboutIcon.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 40, layout: FIXED, placeholder: NONE)
    }
  }
}
`);
let aboutPageVideoTitle = useRef(null);
let aboutPageVideoSubTitle = useRef(null);

	
	useEffect(() => {
        gsap.fromTo(aboutPageVideoTitle.current, {opacity: 0}, {duration: 2, opacity: 1, delay: 1})
        gsap.fromTo(aboutPageVideoSubTitle.current, {opacity: 0}, {duration: 2, opacity: 1, delay: 1.5})
 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <>
        <SEO title={intl.formatMessage({ id: "navbar.about" })} />

        <div className="gridAboutPage">
            <div className="boxAboutPageSideText">
                <FadeIn duration="2s" delay="0.5s">
                    <SideVerticalText />
                </FadeIn>
            </div>
            <div className="boxAboutPageMain">
                <video className="videoPlayer" loop muted autoPlay>
                    <source src={MyVideo} type="video/mp4" />
                </video>
                <video className="videoPlayerMobile" loop muted autoPlay>
                    <source src={MyVideoMobile} type="video/mp4" />
                </video>

                <div className="videoTextBox">
                    <p className="aboutPageVideoTitle" ref={aboutPageVideoTitle}>Tehnomatik 1995</p>

                    <p className="aboutPageVideoSubTitle" ref={aboutPageVideoSubTitle}>
                        {intl.formatMessage({ id: "about.subTitle" })}
                    </p>
                </div>
            </div>

            <div className="boxAboutPageEmptyRight"></div>
            <div className="boxAboutPageBottomText">
                <BottomFactory />
            </div>
        </div>

        <div className="aboutPageSectionAbout">
            <div className="aboutTextImageBox">
                <div className="aboutTextBox">
                    <svg viewBox="0 0 614 3" fill="none">
                        <line x1="148.995" y1="1" x2="613.005" y2="1" stroke="#3A3C40" />
                        <rect width="149" height="3" rx="1.5" fill="#3A3C40" />
                    </svg>

                    <p>{intl.formatMessage({ id: "about.text1" })}</p>
                </div>
                <div className="aboutImageBox">
                    <GatsbyImage
                        image={images.about1.childImageSharp.gatsbyImageData}
                        className="aboutImage"
                        alt="about1"
                        loading="eager" 
                        />
                </div>
            </div>
            <div className="aboutTextImageBox aboutTextImageBoxReverseOrder">
                <div className="aboutImageBox reverseOrderImage">
                    <GatsbyImage
                        image={images.about2.childImageSharp.gatsbyImageData}
                        className="aboutImage"
                        alt="about1"
                        loading="eager" />
                </div>
                <div className="aboutTextBox reverseOrderText">
                    <svg viewBox="0 0 614 3" fill="none">
                        <line x1="148.995" y1="1" x2="613.005" y2="1" stroke="#3A3C40" />
                        <rect width="149" height="3" rx="1.5" fill="#3A3C40" />
                    </svg>

                    <p>{intl.formatMessage({ id: "about.text2" })}</p>
                </div>
            </div>

            <div className="aboutTextImageBox">
                <div className="aboutTextBox">
                    <svg viewBox="0 0 614 3" fill="none">
                        <line x1="148.995" y1="1" x2="613.005" y2="1" stroke="#3A3C40" />
                        <rect width="149" height="3" rx="1.5" fill="#3A3C40" />
                    </svg>
                    <p>{intl.formatMessage({ id: "about.text3" })}</p>
                </div>
                <div className="aboutImageBox">
                    <GatsbyImage
                        image={images.about3.childImageSharp.gatsbyImageData}
                        className="aboutImage"
                        alt="about1"
                        loading="eager" />
                </div>
            </div>
        </div>
        <div className="aboutPageSectionCareer">
            <div className="aboutIconBox">
                <GatsbyImage
                    image={images.aboutIcon.childImageSharp.gatsbyImageData}
                    className="aboutIcon"
                    alt="aboutIcon"
                    loading="eager" />
            </div>
            <p className="careerTitle">
                {intl.formatMessage({ id: "about.career" })}
            </p>
            <p className="careerText">
                {intl.formatMessage({ id: "about.contactUs" })}
                <span className="careerLink">
                    <a href="mailto:karijera@tehnomatik.com">karijera@tehnomatik.com</a>
                </span>
            </p>
        </div>
        <div className="aboutMapTextBox">
            <hr className="aboutHr" />
            <p className="aboutMapText">
                {intl.formatMessage({ id: "index.section04.location" })}
            </p>
            <hr className="aboutHr" />
        </div>
        <div className="aboutPageSectionMap">
            <TehnomatikMap className="tehnomatikGoogleMap" />
        </div>
    </>;
};

export default injectIntl(About);
